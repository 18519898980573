(function($) {
    'use strict';

    var substringMatcher = function(data) {
        return function findMatches(q, cb) {
            var matches = [];
            var substringRegex = new RegExp(q, 'i');
            $.each(data, function(key, value) {
                if (substringRegex.test(value.value)) {
                    matches.push(value);
                }
            });
            cb(matches);
        };
    };

    var filterIds = null;
    $('.typeahead').each(function() {
        var searchData = $(this).data('search');

        $(this).typeahead({
            minLength: 1,
            highlight: true
        }, {
            name: 'products',
            display: 'value',
            source: substringMatcher(searchData)
        });

        $(this).on('keydown', function(event) {
            if (event.keyCode === 13) {
                $(this).blur();
            }
        });

        $(this).bind('typeahead:change', function(ev, value) {
            if(value === '') {
                filterIds = null;
            } else {
                substringMatcher(searchData)(value, function(matches) {
                    filterIds = matches.map(function(match) { return match.id; });
                    applyFilters();
                });
            }
        });

        $(this).bind('typeahead:select', function(ev, suggestion) {
            filterIds = [suggestion.id];
            applyFilters();
        });
    });

    var checkCart = function() {
        if (window.getCookie('cart')) {
            var cartItems = JSON.parse(window.getCookie('cart'));
            var cartLength = Object.keys(cartItems.collection).length + Object.keys(cartItems.product).length;
            $('.cart-count').html(cartLength);
            if(cartLength === 0) {
                window.setCookie('cart', null, 0);
                $('body').removeClass('items-in-cart');
            } else {
                $('body').addClass('items-in-cart');

                if(cartLength === 1){
                    $('.cart-count-singular').show();
                    $('.cart-count-plural').hide();
                } else {
                    $('.cart-count-singular').hide();
                    $('.cart-count-plural').show();
                }
            }

            if($('.checkout-list').length){
                var itemRow = '',
                    itemRowFull = '',
                    removeString = $('.checkout-list').data('translation-remove'),
                    showallString = $('.checkout-list').data('translation-showall'),
                    maxItems = 10;

                if(cartLength > 0) {
                    var count = 1;
                    $.each(cartItems, function(type, items){
                        $.each(items, function(id, item){
                            var tempRow = '<div class="checkout-list-element">' +
                                '<div class="row justify-content-between">' +
                                '<div class="col">' + item.name + '</div>' +
                                '<div class="col text-right">' +
                                '<a href="#" class="cart-remove" data-type="' + item.type + '" data-id="' + item.id + '">' + removeString + '</a>' +
                                '</div>' +
                                '</div>' +
                                '</div>';

                            itemRowFull += tempRow;

                            if(count <= maxItems) {
                                count++;
                                itemRow += tempRow;
                            }
                        });
                    });



                    if(cartLength > maxItems) {
                        itemRow += '<div class="checkout-list-element">' +
                            '<div class="row justify-content-between">' +
                            '<div class="col text-left">' +
                            '<a href="#" data-toggle="modal" data-target="#checkout-list-full">' + showallString + '</a>' +
                            '</div>' +
                            '</div>';
                    }
                }

                $('.checkout-list-body').html(itemRow);
                $('.checkout-list-full-body').html(itemRowFull);


                $('a.cart-remove').on('click',function(e){
                    e.preventDefault();


                    var cartItems = JSON.parse(window.getCookie('cart')),
                        id = $(this).data('id'),
                        type = $(this).data('type');
                    delete cartItems[type][id];

                    window.setCookie('cart', JSON.stringify(cartItems), 2 * (1 / 24));
                    checkCart();
                });
            }
        }
    };
    checkCart();
    
    $('.img-open').magnificPopup({
        type: 'image',
        zoom: {
            enabled: true, // By default it's false, so don't forget to enable it
            duration: 200, // duration of the effect, in milliseconds
            easing: 'linear' // CSS transition easing function
        },
        mainClass: 'mfp-fade',
    });

    $('.gallery-container').each(function() {
        $(this).magnificPopup({
            type: 'image',
            delegate: '.gallery-open',
            zoom: {
                enabled: true, // By default it's false, so don't forget to enable it
                duration: 200, // duration of the effect, in milliseconds
                easing: 'linear' // CSS transition easing function
            },
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            mainClass: 'mfp-fade',
        });
    });


    $('.main-slider').slick({
        arrows: false,
        dots: true,
        autoplay: false,
        autoplaySpeed: 4000
    });

    $('.images-slider').slick({
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
        ]
    });

    $('.product-slider-0').addClass('active').slick({
        arrows: false,
        dots: true,
        autoplay: false
    });


    $('.product-color-filter a').on('click', function(e){
        e.preventDefault();

        var prevSlider = $('.product-slider-container').find('.active');
        prevSlider.slick('unslick').hide().removeClass('active');

        var slider = $('.product-slider-' + $(this).data('color-key'));

        slider.show().addClass('active').slick({
            arrows: false,
            dots: true,
            autoplay: false
        });
    });

    $('.youtube-video-link').on('click', function() {
        $(this).html('<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/' + $(this).data('video-id') + '?autoplay=1" frameborder="0" allowfullscreen></iframe>');
        return false;
    });

    $('.blog-loadmore').on('click',function(){
        var chunkToShow = $(this).data('open-chunks')+1;
        var chunksTotal = $(this).data('chunks');
        $('.chunk-'+ chunkToShow).show();

        if(chunkToShow >= chunksTotal-1){
            $(this).hide();
        } else {
            $(this).data('open-chunks', chunkToShow);
        }
    });


    $('select.contact-select').on('change',function(){
        window.location.href = $(this).find(':selected').data('link');
    });


    // Collection JS Filter deactivated
    if(false) {
        $('.btn-collection-filter').on('click', function (e) {
            e.preventDefault();
            var chosenCol = $(this).data('value');
            if (chosenCol === 'all') {
                $('.product-col').show();
            } else {
                $('.product-col').hide();
                $('.product-' + chosenCol).show();
            }
            $('.btn-collection-filter').removeClass('active');
            $(this).addClass('active');
        });

        if (window.collections_filter) {
            $('.btn-collection-filter[data-value="' + window.collections_filter + '"]').trigger('click');
        }
    }

    $('.product-filter .dropdown-toggle').on('click', function(e){
        e.preventDefault();
        var dd = $(this).closest('.product-filter-select'),
            dmenu = dd.find('.dropdown-menu');


        if(dmenu.hasClass('show')){
            $('.dropdown-menu').removeClass('show');
        } else {
            $('.dropdown-menu').removeClass('show');
            dmenu.toggleClass('show');
        }
    });


    $(document).mouseup(function(e) {
        var dd = $('.dropdown');

        // if the target of the click isn't the container nor a descendant of the container
        if (!dd.is(e.target) && dd.has(e.target).length === 0){
            dd.find('.dropdown-menu').removeClass('show');
        }
    });

    var productsToShow = {};

    var applyFilters = function() {
        $('.product').each(function(){
            var element = $(this);
            var is_visible = true;
            var filter = element.data('filter');

            var image = null;
            $.each(filter, function(name, val){
                if(productsToShow[name] && productsToShow[name].length) {
                    var hits = 0;
                    $.each(val, function(s_key, s_val){
                        if(productsToShow[name].indexOf(s_val) !== -1){
                            if(name === 'mainColor') {
                                if(!image && filter.images.mainColor[s_val]) {
                                    image = filter.images.mainColor[s_val];
                                }
                            } else if(name === 'secondaryColor') {
                                if(!image && filter.images.secondaryColor[s_val]) {
                                    image = filter.images.secondaryColor[s_val];
                                }
                            }
                            hits++;
                        }
                    });
                    if(hits === 0){
                        is_visible = false;
                    }
                }
            });
            if(image) {
                element.find('.product-img').attr('src', image);
            } else {
                element.find('.product-img').attr('src', filter.defaultImage);
            }

            if(filterIds !== null && filterIds.indexOf(filter.id) === -1) {
                is_visible = false;
            }

            if(is_visible) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    };

    $('.product-filter .dropdown-item').on('click', function(e){
        e.preventDefault();
        var dd = $(this).closest('.product-filter-select'),
            value = $(this).data('value'),
            filterCategory = dd.data('filter');

        if(productsToShow[filterCategory]){
            if(productsToShow[filterCategory].indexOf(value) !== -1){
                productsToShow[filterCategory].splice(productsToShow[filterCategory].indexOf(value), 1);
            } else {
                productsToShow[filterCategory].push(value);
            }
        } else{
            productsToShow[filterCategory] = [value];
        }

        applyFilters();

        $(this).toggleClass('checked');

        var optionLabels = [];
        dd.find('.dropdown-item').each(function(){
            if($(this).hasClass('checked')){
                var label = $(this).data('label');
                if(optionLabels.indexOf(label) !== -1){
                    optionLabels.splice(optionLabels.indexOf(label), 1);
                } else {
                    optionLabels.push(label);
                }
            }
        });

        if(optionLabels && optionLabels.length){
            dd.find('.filter-counter').html(optionLabels.length).show();
        } else {
            dd.find('.filter-counter').hide();
        }
    });

    $('.reset-filter').on('click', function(e){
        e.preventDefault();

        $(this).closest('form').find('.product-filter-select').each(function(){
            $(this).find('input').attr('value','');
            var placeholder = $(this).find('.btn-select').data('placeholder');
            $(this).find('.dropdown-placeholder').html(placeholder);
        });


        $('.product').each(function(){
            var element = $(this);
            var filter = element.data('filter');
            element.find('.product-img').attr('src', filter.defaultImage);
        });


        productsToShow = [];
        $('.filtered-products').find('.product').show();
        $('.dropdown-item').removeClass('checked');
        $('.filter-counter').hide();
    });

//  Cookie: Banner
    var cookieButton = $('#cookie-close-btn');
    var cookieBanner = $('#cookie-banner');

    if(window.getCookie('cookies_accepted') !== 'yes') {
        cookieBanner.show();
        $('body').css('padding-bottom', cookieBanner.outerHeight());
    }

    cookieButton.on('click', function(event) {
        event.preventDefault();
        var domain = window.location.hostname.replace(/^[^\.]*\./, '.');
        window.setCookie('cookies_accepted', 'yes', 365, domain);
        cookieBanner.hide();
        $('body').css('padding-bottom', '');
        return false;
    });


//  Cookie: Cart
    $('a.cart-add').on('click',function(e){
        e.preventDefault();
        var cartItems = JSON.parse(window.getCookie('cart')),
            id = $(this).data('id'),
            name = $(this).data('name'),
            type = $(this).data('type');

        if(!cartItems) {
            cartItems = {
                product: {},
                collection: {},
            };
        }

        cartItems[type][id] = {
            id: id,
            type: type,
            name: name
        };
        $('.cart-info').addClass('show');
        window.setCookie('cart', JSON.stringify(cartItems), 2 * (1 / 24));
        checkCart();
    });

    $('.close-cart-info').on('click',function(){
        $('.cart-info').removeClass('show');
    });

    $('.copy-link').on('click',function(){
        var dataPath = $(this).data('path');

        copyTextToClipboard(dataPath);
    });

    function copyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand('copy');
            // var msg = successful ? 'successful' : 'unsuccessful';
            // console.log('Copying text command was ' + msg);
        } catch (err) {
            // console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }
}(jQuery));
